import { OwnUpBodyItalic, OwnUpBodyMedium } from '@rategravity/own-up-component-library';
import React from 'react';

export const andrew = {
  name: 'Andrew',
  catchphrase: <>&#8220;If I had a question, Patrick was just a phone call or text away.&#8221;</>,
  shortReview: (
    <>
      Andrew recently had an offer accepted on an affordable housing unit, but due to the
      condo&#8217;s unique circumstance...
    </>
  ),
  review: (
    <>
      <OwnUpBodyMedium variant="body1">
        Andrew had been looking for a home after renting for more than two years and had an offer
        accepted on an affordable housing unit.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        &#8220;I graduated about two years ago, and after renting a place for so long I thought it
        was time to buy. I work as a project manager in tech, and was starting to look for a place
        near my job. The second offer I made was accepted and the next step was to secure a
        mortgage.&#8221;
      </OwnUpBodyItalic>
      <br />
      <OwnUpBodyMedium variant="body1">
        Unfortunately, the condo came with a bit of a quirk &#8212; the condo association was facing
        a pending lawsuit that made lenders unwilling to offer a competitive rate for a mortgage.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        &#8220;The challenging aspect about the mortgage for this place was that there was a pending
        lawsuit ... many of the mortgage companies didn’t want to take it on. The selling agent had
        recommended a lender that would be comfortable with the situation but the rates were
        ridiculously high, so I decided to look at other options. No one wanted to take the mortgage
        or the rates were too high.&#8221;
      </OwnUpBodyItalic>
      <br />
      <OwnUpBodyMedium variant="body1">
        Andrew&#8217;s realtor recommended he reach out to Own Up.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        &#8220;I talked to my realtor and one of the mortgage options she recommended was Own Up. I
        really like the idea of supporting startups, so I reached out. Patrick immediately
        responded. We went through all the details, and then Patrick shopped my info around and
        found one that would be willing to supply me with a rate that was very reasonable. Patrick
        was there every step of the way and once we found a lender he made sure it was smooth
        sailing from there on.&#8221;
      </OwnUpBodyItalic>
      <br />
      <OwnUpBodyMedium variant="body1">
        Own Up helped Andrew find a competitive rate through our network that saved him
        approximately $13K over the life of his loan , and served as an advisor as Andrew navigated
        the rest of the home buying process.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        &#8220;Patrick was always there throughout the whole process. If I had a question he was
        just a phone call or text away. He answered any questions I had straight away and really got
        to the bottom of things. All these old-fashioned processes are slowly being replaced, and I
        think it&#8217;s really beneficial that Own Up is rethinking the mortgage process.&#8221;
      </OwnUpBodyItalic>
    </>
  )
};
