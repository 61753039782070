import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  OwnUpBody,
  OwnUpBodyItalic,
  OwnUpBodyMedium,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpLargeBodyMedium,
  SLATE_100
} from '@rategravity/own-up-component-library';
import fecha from 'fecha';
import React, { useState } from 'react';
import styled from 'styled-components';
import { truncate } from '../../../modules/formatting';
import { LinkedButton } from '../../button';
import { Rating } from '../../rating';

const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RatingWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding-top: ${({ theme }) => theme.spacing(2.5)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const DateText = styled(OwnUpBodyItalic)`
  color: ${SLATE_100};
  padding-top: ${({ theme }) => theme.spacing(2.5)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const TextWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const NameWrapper = styled(OwnUpBodyMedium)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export interface ReviewProps {
  content: string;
  createdAt: string;
  name: string;
  score: number;
  title: string;
}

export const Review = ({ content, createdAt, name, score, title }: ReviewProps) => {
  const [lessText, setLessText] = useState(true);
  const tracker = useTrackingInstance();

  return (
    <OwnUpGridContainer variant="slim">
      <OwnUpGridOffset xs={0} sm={1} md={0} lg={1} />
      <OwnUpGridItem xs={12} sm={10} md={12} lg={10}>
        <ReviewWrapper>
          <RatingWrapper>
            <Rating rate={score} />
          </RatingWrapper>
          <TextWrapper>
            <OwnUpLargeBodyMedium variant="body1">{title}</OwnUpLargeBodyMedium>
          </TextWrapper>
          <TextWrapper>
            <OwnUpBody variant="body1">{lessText ? truncate(content, 350) : content}</OwnUpBody>
          </TextWrapper>
          <NameWrapper variant="body1" style={{ color: SLATE_100 }}>
            {name}
          </NameWrapper>
          <DateText variant="body1">{fecha.format(new Date(createdAt), 'MMMM DD, YYYY')}</DateText>
          {content.length > 350 ? (
            <TextWrapper>
              <LinkedButton
                onClick={() => {
                  if (lessText) {
                    tracker.track('Reviews Page Review Clicked', {
                      action: 'readMore',
                      'page-location': `${name} review`
                    });
                  }
                  setLessText(!lessText);
                }}
              >
                {lessText ? 'Read More' : 'Read Less'}
              </LinkedButton>
            </TextWrapper>
          ) : null}
        </ReviewWrapper>
      </OwnUpGridItem>
      <OwnUpGridOffset xs={0} sm={1} md={0} lg={1} />
    </OwnUpGridContainer>
  );
};
