import { CACTUS_100, FOG_100, OwnUpIcon } from '@rategravity/own-up-component-library';
import { StarFilledIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/star-filled';
import React, { Fragment } from 'react';

export const Rating = ({ rate }: { rate: number }) => (
  <Fragment>
    {Array.from({ length: 5 }, (_, index) => (
      <OwnUpIcon key={index} icon={StarFilledIcon} color={index < rate ? CACTUS_100 : FOG_100} />
    ))}
  </Fragment>
);
