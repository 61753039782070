import { MOSS_50, OwnUpGridItem, PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { OverlineText } from '../../overline-text';

import { Review as ReviewIndividual, ReviewProps } from '../review';

export const Section = styled.section`
  background-color: ${MOSS_50};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0)};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const SectionHeader = styled(OverlineText)`
  text-transform: uppercase;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
`;

const PaddedGridItem = styled(OwnUpGridItem)`
  padding-top: ${({ theme }) => theme.spacing(6)}px !important;
  ${({ theme }) => theme.breakpoints.only('md')} {
    padding-top: ${({ theme }) => theme.spacing(8)}px !important;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }) => theme.spacing(10)}px !important;
  }
`;

export const Review = ({ data }: { data: ReviewProps }) => (
  <PaddedGridItem xs={12} md={4}>
    <ReviewIndividual {...data} />
  </PaddedGridItem>
);

export const PaginationWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }) => theme.spacing(7)}px;
  }
`;
