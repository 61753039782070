import { Theme, useMediaQuery } from '@material-ui/core';
import {
  ALOE_100,
  CHARCOAL_100,
  OwnUpIconButton,
  OwnUpOverline
} from '@rategravity/own-up-component-library';
import { ChevronLeftIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-left';
import { ChevronRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-right';
import React, { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';

const NumbersWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(0, 0.5)};
  }
  padding: ${({ theme }) => theme.spacing(0, 5)};
`;

const NumberButton = styled.button`
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(0, 2.5)};
  }
  padding: ${({ theme }) => theme.spacing(0, 3)};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const NumberText = styled(OwnUpOverline)<{ $isActive: boolean }>`
  color: ${({ $isActive }) => ($isActive ? ALOE_100 : CHARCOAL_100)};
`;

export const PaginationNumbers = ({
  currentPage,
  numOfPages,
  scrollToTop,
  setCurrentPage
}: {
  currentPage: number;
  numOfPages: number;
  scrollToTop: () => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => {
  const theme = useTheme() as Theme;
  const limit = useMediaQuery(theme.breakpoints.down('sm')) ? 3 : 9; // max page numbers to display in nav
  const center = Math.ceil(limit / 2); // center point of page numbers in nav

  // Determine the starting number of the nav.
  // Assume 1 - meaning we are at or below the center point.
  let navStart = 1;
  // Check if current page is on the last batch of pages displayed in the nav and above the center point of the nav.
  if (currentPage > numOfPages - center) {
    navStart = numOfPages - limit + 1;
    // Last, check if the current page is somewhere in between the first and the last batch of pages.
  } else if (currentPage > center) {
    navStart = currentPage - center + 1;
  }
  const nums = [];
  // add page numbers to the nav from starting point until the limit
  for (; nums.length < limit; navStart++) {
    nums.push(navStart);
  }

  return (
    <NumbersWrapper>
      {nums.map((number) => (
        <NumberButton
          onClick={() => {
            setCurrentPage(number);
            scrollToTop();
          }}
          aria-current={currentPage === number && 'page'}
          key={number}
        >
          <NumberText $isActive={number === currentPage}>{number}</NumberText>
        </NumberButton>
      ))}
    </NumbersWrapper>
  );
};

const PaginationWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Pagination = ({
  currentPage,
  numOfPages,
  scrollToTop,
  setCurrentPage
}: {
  currentPage: number;
  numOfPages: number;
  scrollToTop: () => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => (
  <PaginationWrapper aria-label="Reviews pages">
    <OwnUpIconButton
      icon={ChevronLeftIcon}
      name="Backward review button"
      size={2}
      disabled={currentPage === 1}
      onClick={() => {
        setCurrentPage(currentPage - 1);
        scrollToTop();
      }}
    />
    <PaginationNumbers
      numOfPages={numOfPages}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      scrollToTop={scrollToTop}
    />
    <OwnUpIconButton
      icon={ChevronRightIcon}
      name="Forward review button"
      size={2}
      disabled={currentPage === numOfPages}
      onClick={() => {
        setCurrentPage(currentPage + 1);
        scrollToTop();
      }}
    />
  </PaginationWrapper>
);
