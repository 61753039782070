import {
  CHARCOAL_100,
  OwnUpGridContainer,
  OwnUpGridWrapper,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const ImotionalWrapper = styled(OwnUpGridWrapper)`
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;
export const ImotionalContainer = styled(OwnUpGridContainer)`
  flex-direction: row;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    flex-direction: row-reverse;
  }
`;

export const WidgetSection = styled.div`
  background-color: white;
  border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;

export const YotpoLogo = styled.img`
  width: 78px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    width: auto;
  }
`;

export const NumberOfYotpoReviews = styled.div`
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const ExcellentRating = styled.div`
  color: ${CHARCOAL_100};
  font-size: 24px;
  line-height: 18px;
  font-weight: bold;
`;

export const RatingWrapper = styled.div`
  display: flex;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  justify-content: left;
`;

export const StarsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
`;
