import { OwnUpBodyItalic, OwnUpBodyMedium } from '@rategravity/own-up-component-library';
import React from 'react';

export const evan = {
  name: 'Evan',
  catchphrase: (
    <>
      &#8220;We need to be looking at things differently in the digital age and Own Up&#8217;s the
      only one doing that.&#8221;
    </>
  ),
  shortReview:
    'Evan says his biggest takeaway from the home buying process is, "Shop around, shop around, shop around"...',
  review: (
    <>
      <OwnUpBodyMedium variant="body1">
        Evan says his biggest takeaway from the home buying process is that people need to look at
        purchasing a mortgage like they look at purchasing anything else in the digital age:
        &#8220;Shop around, shop around, shop around.&#8221;
        <br />
        <br />
        Here’s his story.
        <br />
        <br />
        Evan and his wife Jia had been renting their apartment in Boston when they thought it might
        be time to look at purchasing a home.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        &#8220;I’ve been in Boston since 2012 and was all fine renting but my wife was like,
        &#8216;We&#8217;re paying so much in rent for an apartment so let’s look into buying a
        house.&#8217; When we actually started looking into buying a home, we realized it was
        doable. We were married, we lived together, and with our lease coming up we thought ok!
        Let’s look around. We were looking in the Greater Boston area, Brighton specifically, and
        nothing too far away from our work.&#8221;
      </OwnUpBodyItalic>
      <br />
      <OwnUpBodyMedium variant="body1">
        Evan found out about Own Up from a faculty member at Harvard Business School who had been
        serving as an advisor to the company, so he reached out to start the pre-approval process.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        &#8220;At the very start of the process I had reached out to a different mortgage company
        that did a super quick call with me, did a hard check on my credit, gave me a rate, and said
        this is what you can get. Then it was just click, conversation over.
        <br />
        <br />
        &#8220;But talking with Patrick was totally different. He took the time to learn more about
        us, figure out what our finances were, and what we were looking to do. The first
        conversation with him was maybe 40 minutes or so, and with my wife on the line Patrick gave
        us really clear and unbiased advice on what we should be looking for, what we could
        tentatively afford, and where we should be looking. It was really helpful. And of course
        it’s great that Own Up only does soft inquiries so our credit score wouldn’t be
        affected.&#8221;
      </OwnUpBodyItalic>
      <br />
      <OwnUpBodyMedium variant="body1">
        Evan and Jia hit a couple of speed bumps in their route to securing a loan. First, Evan and
        his wife were both students, and his wife was working a new hourly position that made it
        challenging to quantify her income. Second, after making an offer their condo failed a radon
        test during their inspection, making it challenging for a lender to move forward with a
        loan. Patrick served as an advisor throughout the process, helping Evan and Jia find
        solutions so they could continue moving forward with purchasing their home.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        &#8220;When we first talked to Patrick, Jia was working a new full-time job, but it wasn’t a
        salaried position so we couldn’t use her income to qualify for a mortgage. Patrick walked us
        through the process and helped us figure out what we could afford and which lenders we could
        work with given our situation.
        <br />
        <br />
        Then, during the inspection we did a radon test and it came back high. Unfortunately, the
        seller and the homeowner&#8217;s association weren’t interested in mitigating it, so I
        offered to take care of it after we purchased the home. The problem was that I
        couldn&#8217;t get that agreement in writing so a lender wouldn&#8217;t approve the loan
        without a passed inspection. That was definitely a scary part of the process. Patrick served
        as a really good sounding board as we juggled all the different parties involved. Eventually
        I was able to get it in writing and we were able to move ahead.&#8221;
      </OwnUpBodyItalic>
      <br />
      <OwnUpBodyMedium variant="body1">
        Through working with Own Up, Evan was able to secure a mortgage with a rate that saved them
        approximately $17K over the life of their loan. He says that having a trusted partner to
        help him through the process was huge.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        &#8220;All of the options quoted through Own Up were better than what I had been quoted
        before. I feel like part of that was because Patrick dug in a little bit deeper into
        potential options and really understood how the whole system operates. He laid out
        everything really clearly to the point that securing a mortgage was probably the easiest
        part of the home buying process.
        <br />
        <br />I think people get taken advantage of when they&#8217;re under pressure to buy, and
        &#8216;experts&#8217; are telling them things like you have to take this rate, or make this
        offer. Own Up felt like a neutral third party who just laid out our options, showed us the
        costs and the fees, and said, &#8216;Let us know how we can help.&#8217; They kind of let
        the cards fall where they may, whereas it seems like typical mortgage brokers who are trying
        to get their commission have a more vested interest in pushing things along quickly.&#8221;
      </OwnUpBodyItalic>
      <br />
      <OwnUpBodyMedium variant="body1">
        When it comes to giving advice to other future homebuyers, Evan says that people need to
        look at the mortgage process as they look at any other big purchases: they need to shop
        around, and Own Up&#8217;s innovative technology makes that possible.
      </OwnUpBodyMedium>
      <br />
      <OwnUpBodyItalic variant="body1">
        In the digital age we should automatically be trained to say hey, how can I do this more
        efficiently? Where can I get the best deal? We Google coupon codes as we&#8217;re checking
        out in stores, we look for the best deals with online retailers, and we ask for help all the
        time. But when it comes to a big purchase like a mortgage, it&#8217;s funny how we how we
        don’t automatically do the same thing. I happened upon Own Up by chance because I was lucky
        enough to work with my boss who was familiar with the company. But before that I
        didn&#8217;t know that there was so much flexibility in the rates. What Own Up is doing is
        really wonderful, and we just need to train people to shop around for a mortgage. The fact
        that it&#8217;s free for anyone to reach out to Own Up and to shop for a rate without it
        killing your credit is just great. We need to be looking at things differently in the
        digital age and Own Up&#8217;s the only one doing that.
      </OwnUpBodyItalic>
    </>
  )
};
