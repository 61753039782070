import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const ReviewSingleImageEmilyEvan = () => (
  <StaticImage
    src="../../images/reviews/emily-and-evan.png"
    alt="Happy Own Up customers Emily and Evan smile at the camera"
    placeholder="none"
    quality={100}
    width={1557}
    breakpoints={[312, 400, 511, 767, 1023, 1279]}
    loading="eager"
  />
);

export const TrustpilotImotionalImage = () => (
  <StaticImage
    src="../../images/reviews/trustpilot-imotional.png"
    alt="Happy family with dog"
    placeholder="none"
    quality={100}
    width={1557}
    breakpoints={[312, 400, 511, 767, 1023, 1279]}
    loading="eager"
  />
);
export const YotpoImotionalImage = () => (
  <StaticImage
    src="../../images/reviews/yotpo-imotional.png"
    alt="Happy family from yotpo"
    placeholder="none"
    quality={100}
    width={1557}
    breakpoints={[312, 400, 511, 767, 1023, 1279]}
    loading="eager"
  />
);
