import {
  ownUpDarkTheme,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';

import { ReviewSingleImageEmilyEvan } from '../../static-images/reviews';
import {
  Content,
  CTAArea,
  CTAHeadline,
  CTASubHeadline,
  EmptyWhiteGap,
  ImageWrapper,
  ReviewNames,
  ReviewPlace,
  Section
} from './elements';

const PureHero = () => {
  return (
    <Content>
      <ImageWrapper>
        <ReviewSingleImageEmilyEvan />
      </ImageWrapper>
      <CTAArea>
        <CTAHeadline>
          “They got us the preapproval in like 10 minutes! They absolutely saved the day.”
        </CTAHeadline>
        <ReviewNames>Emily & Evan</ReviewNames>
        <ReviewPlace>Peabody, MA</ReviewPlace>
        <CTASubHeadline>
          “Everyone was so kind, so patient, so informative, and really just warm. They gave me so
          much confidence.” Own Up matched them to a lender at a preferred interest rate, saving
          them $38,000 over the life of their loan. “Own Up was a champion for us throughout the
          entire process … I can’t describe what saving that much money means to us!
        </CTASubHeadline>
      </CTAArea>
    </Content>
  );
};

export const ReviewsHero = () => {
  return (
    <OwnUpThemeProvider theme={ownUpDarkTheme}>
      <EmptyWhiteGap />
      <Section aria-label="reviews-hero">
        <OwnUpGridWrapper>
          <PureHero />
        </OwnUpGridWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
