import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';
import { OverlineText } from '../../overline-text';

export const Section = styled.section`
  background-color: white;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0)};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const SectionHeader = styled(OverlineText)`
  text-transform: uppercase;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
`;
