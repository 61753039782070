import { OwnUpGridContainer, OwnUpGridItem } from '@rategravity/own-up-component-library';
import React from 'react';

import { TrustpilotImotionalImage } from '../../static-images/reviews';

import { ImotionalWrapper, WidgetSection } from './elements';

export const TrustpilotImotional = () => (
  <ImotionalWrapper>
    <OwnUpGridContainer variant="legacy">
      <OwnUpGridItem xs={0} md={4}>
        <WidgetSection>
          <div
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="5e95b90631db9d0001f39ae9"
            data-style-height="150px"
            data-style-width="100%"
            data-theme="light"
          >
            <a
              href="https://www.trustpilot.com/review/ownup.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </WidgetSection>
      </OwnUpGridItem>
      <OwnUpGridItem xs={12} md={8}>
        <TrustpilotImotionalImage />
      </OwnUpGridItem>
    </OwnUpGridContainer>
  </ImotionalWrapper>
);
