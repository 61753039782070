import { MANGO_40, OwnUpOverline, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const OverlineWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const OverlineText = styled(OwnUpOverline)`
  background-color: ${MANGO_40};
  display: inline;
  border-radius: 4px;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5, 1, 0, 1)};
`;
