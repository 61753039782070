import { OwnUpGridItem } from '@rategravity/own-up-component-library';
import React from 'react';

import YotpoLogoImage from '../../../images/reviews/yotpo.svg';
import { Rating } from '../../rating';
import { YotpoImotionalImage } from '../../static-images/reviews';

import {
  ExcellentRating,
  ImotionalContainer,
  ImotionalWrapper,
  NumberOfYotpoReviews,
  RatingWrapper,
  StarsWrapper,
  WidgetSection,
  YotpoLogo
} from './elements';

interface Props {
  numberOfReviews: number;
}
export const YotpoImotional = ({ numberOfReviews }: Props) => (
  <ImotionalWrapper>
    <ImotionalContainer variant="legacy">
      <OwnUpGridItem xs={12} md={8}>
        <YotpoImotionalImage />
      </OwnUpGridItem>
      <OwnUpGridItem xs={12} md={4}>
        <WidgetSection>
          <StarsWrapper>
            <ExcellentRating>Excellent</ExcellentRating>
            <RatingWrapper>
              <Rating rate={5} />
            </RatingWrapper>
          </StarsWrapper>
          <NumberOfYotpoReviews>
            Rated <b>4.9</b> / 5 on <b>{numberOfReviews}</b> certified reviews on
          </NumberOfYotpoReviews>
          <YotpoLogo src={YotpoLogoImage} alt={''} />
        </WidgetSection>
      </OwnUpGridItem>
    </ImotionalContainer>
  </ImotionalWrapper>
);
