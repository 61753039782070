import { FOG_20, OwnUpGridWrapper, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const ImotionalWrapper = styled(OwnUpGridWrapper)`
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const WidgetSection = styled.div`
  background-color: ${FOG_20};
  border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;
