import {
  OwnUpGridContainer,
  OwnUpGridWrapper,
  OwnUpThemeProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React, { useEffect, useRef, useState } from 'react';

import { decodeHtml } from '../../../modules/formatting';
import { OverlineWrapper } from '../../overline-text';
import { Pagination } from '../pagination';
import { YotpoImotional } from '../yotpo-imotional';
import { PaginationWrapper, Review, Section, SectionHeader } from './elements';

export const YotpoSection = () => {
  const [totalReviews, setTotalReviews] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const scrollToRef = () => {
    if (reviewsRef && reviewsRef.current) {
      reviewsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const fetchPage = async (page: number) => {
    const url = `/api/reviews/reviews?per_page=6&page=${page}`;
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setReviews(data.reviews);
        setTotalReviews(data.bottomline.total_reviews);
      })
      .catch((_) => null); // no-op
  };

  useEffect(() => {
    // The default state will be used in the event of an error.
    fetchPage(currentPage).catch((_) => null);
  }, [currentPage]);

  const numberOfPages = Math.ceil(totalReviews / 6);

  return (
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <Section aria-label="yotpo-reviews">
        <OverlineWrapper>
          <SectionHeader>Community reviews</SectionHeader>
        </OverlineWrapper>
        <YotpoImotional numberOfReviews={totalReviews} />
        <OwnUpGridWrapper>
          <div ref={reviewsRef}></div>
          <OwnUpGridContainer variant="legacy">
            {reviews.map(
              (
                { score, content, title, created_at: createdAt, user: { display_name: name } },
                index
              ) => (
                <Review
                  key={index}
                  data={{
                    score,
                    content: decodeHtml(content),
                    title: decodeHtml(title),
                    createdAt,
                    name
                  }}
                />
              )
            )}
          </OwnUpGridContainer>
        </OwnUpGridWrapper>

        <PaginationWrapper>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            numOfPages={numberOfPages}
            scrollToTop={scrollToRef}
          />
        </PaginationWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
