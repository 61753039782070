import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  IconLink,
  MINT_100,
  MOSS_50,
  OwnUpBody,
  OwnUpBodyItalic,
  OwnUpGridContainer,
  OwnUpModalProps,
  OwnUpSideModal,
  PropsWithTheme,
  SLATE_100
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import React, { Fragment, ReactNode, useState } from 'react';
import styled from 'styled-components';
import Quote from '../../images/icons/quote-square.svg';
import { LinkedButton } from '../button';
import { Rating } from '../rating';
import { OwnUpLargeBodyMedium } from '../typography';

export const Section = styled.section`
  background-color: ${MOSS_50};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }

  :not(:first-child) {
    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
      padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(6)}px`};
    }
  }
`;

export const RatingWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => `-${theme.spacing(0.5)}px`};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(0.5)}px`};
  }

  display: flex;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
  justify-content: center;
`;

export const ReviewsWrapper = styled(OwnUpGridContainer)`
  margin-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(6)}px`};
`;

export const BodyWrapper = styled.div`
  border: 1px solid ${MINT_100};
  border-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(0.5)}px`};
  background-color: white;
  padding: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(7)}px`};
  }
`;

const QuoteIcon = styled.img`
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
`;

export const DetailWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
`;

export const ReadMoreLink = ({
  onClick
}: {
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}) => (
  <IconLink link="#" icon={<ArrowRightIcon />} onClick={onClick}>
    Read more
  </IconLink>
);

export interface ReviewIndividualProps {
  image: JSX.Element | null;
  name: string;
  catchphrase: string | JSX.Element;
  shortReview: string | JSX.Element;
  review: JSX.Element;
}

const ModalTitleWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2.5)}px`};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
  }
`;

const ModalRatingWrapper = styled.div`
  display: flex;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
  justify-content: left;
`;

const ModalTitle = ({ catchphrase }: { catchphrase: string | JSX.Element }): ReactNode => (
  <Fragment>
    <ModalRatingWrapper>
      <Rating rate={5} />
    </ModalRatingWrapper>
    <ModalTitleWrapper>{catchphrase}</ModalTitleWrapper>
  </Fragment>
);

export const ReviewIndividual = ({
  image,
  name,
  catchphrase,
  shortReview,
  review
}: ReviewIndividualProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const trackingInstance = useTrackingInstance();

  const onClick = () => {
    trackingInstance.track(`review-${name}-readMore`);
    setIsOpen(true);

    return false;
  };

  return (
    <Fragment>
      <BodyWrapper>
        <div>
          <QuoteIcon
            src={Quote}
            alt={''} // skip on screen readers
          />
          <OwnUpLargeBodyMedium variant="body1">{catchphrase}</OwnUpLargeBodyMedium>
          <DetailWrapper>
            <OwnUpBody variant="body1">{shortReview}</OwnUpBody>
          </DetailWrapper>
        </div>
        <div>
          <ProfileWrapper>
            {image}
            <OwnUpBodyItalic variant="body1" style={{ color: SLATE_100 }}>
              {name}
            </OwnUpBodyItalic>
          </ProfileWrapper>
          <LinkedButton onClick={onClick}>Read more</LinkedButton>
        </div>
      </BodyWrapper>
      <OwnUpSideModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={ModalTitle({ catchphrase }) as OwnUpModalProps['title']}
      >
        {review}
      </OwnUpSideModal>
    </Fragment>
  );
};
