export function decodeHtml(html: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

/**
 *
 * @param text text to truncate
 * @param limit character count where truncation starts
 */
export const truncate = (text: string, limit: number) =>
  text.length > limit ? `${text.substr(0, limit - 1)}...` : text;
