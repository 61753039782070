import {
  ALOE_100,
  IconLink,
  OwnUpFillButtonPrimaryAccent,
  p22MackinacBookMixin,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customBreakpoint = 1228;
const customMobileBreakpoint = 870;

export const Section = styled.section`
  background-color: ${ALOE_100};
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8)}px;
  }
`;
export const EmptyWhiteGap = styled.div`
  width: 100%;
  background-color: white;
  height: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8)}px;
  display: none;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    display: block;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0, 4)};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0, 0)};
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
  }
  gap: 0 32px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  width: 100%;
`;

export const Button = styled(OwnUpFillButtonPrimaryAccent)`
  width: 100%;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    width: 312px;
  }
`;

export const ImageWrapper = styled.div`
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-row: 1 / span 2;
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(-16)}px;
  }
`;
export const CTAArea = styled.div`
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
  margin-left: 0;
  width: 100%;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-column: 2 / span 2;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    max-width: 612px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    margin-left: -50px;
  }
`;
export const CTAHeadline = styled.h1`
  font-size: 34px;
  line-height: 40px;
  margin: 0;
  ${p22MackinacBookMixin}
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    font-size: 40px;
    line-height: 48px;
  }
`;
export const CTASubHeadline = styled.h2`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 400;
  margin: 0;
`;

export const ReviewNames = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;
export const ReviewPlace = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const ReadMore = styled(IconLink)`
  color: white;
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;
